@import "~@/styles/variables/variables.scss";











































































































































































::v-deep {
	.el-form {
		margin-top: 20px;
	}
	.el-form-item {
		flex: 1;
		margin-right: 10px;
		margin-bottom: 0;
		&:last-child {
			margin-right: 0;
		}
	}
}
.el-form-item-group {
	display: flex;
	align-items: center;
	height: 32px;
	margin-bottom: 20px;
	.label {
		width: 70px;
		font-size: 14px;
		color: #222222;
		margin-right: 10px;
	}
}
.add-key {
	.step {
		.sub-title {
			font-size: 14px;
			font-weight: 600;
			color: #222222;
			line-height: 21px;
			margin-bottom: 14px;
		}
		.tip {
			font-size: 12px;
			color: #666666;
			line-height: 18px;
		}
	}
	.step0 {
		.content {
			padding: 6px 0 16px 20px;
			width: 100%;
			height: 142px;
			background: #ebeefa;
			border-radius: 4px;
			font-size: 14px;
			color: #6b7399;
			line-height: 20px;
			box-sizing: border-box;
		}
	}
}
